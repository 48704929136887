import { IsNotEmpty, IsNumber, IsUUID } from 'class-validator';

export class GetCompanyEmployeeSubstitutesDto {
  @IsUUID()
  @IsNotEmpty()
  companyId: string;

  @IsNumber()
  @IsNotEmpty()
  limit: number;

  @IsNumber()
  @IsNotEmpty()
  offset: number;

  @IsUUID()
  @IsNotEmpty()
  employeeId: string;
}
